<template>
    <div class="newbill">
        <!-- 1.时间选择条件搜索框 -->
        <div class="searchForm">
            <el-page-header @click="backOut" icon="el-icon-arrow-left" class="pageHeader"
                :content="`${$route.query.data || ''}消费统计详情`"></el-page-header>
            <div class="flex-nowrap-flex-start">
                <div class="ml-10">
                    <el-button :loading="exportLoading" @click.stop="exportData" class="button-el" size="">
                        <icon-font type="icon-daochushuju" class="button-icon-font" />导出
                    </el-button>
                </div>
                <div class="font-refresh-out flex-nowrap-center-center ml-10" @click="refreshData">
                    <icon-font v-if="loading == false" type="icon-shuaxin" class="font-refresh" />
                    <i v-else class="el-icon-loading loding-color"></i>
                </div>
            </div>
        </div>
        <!-- 2.数据维度条件搜索框 -->
        <div class="flex-nowrap-flex-start mb-20">
            <div v-if="$route.query.type == 'amount'">
                <div class="search_item">
                    <span class="search_item_title">公司名称</span>
                    <el-select size="mini" v-model="searchForm.uid" placeholder="请选择查询的公司" @change="setDataList" clearable
                        filterable>
                        <el-option v-for="(item, index) in select_list" :key="index" :label="`${item.company}`"
                            :value="item.uid">
                            <div class="flex-nowrap-space-between">
                                <div :class="item.status == 1 ? 'no-red' : ''">
                                    <span>{{ item.company }}</span>
                                </div>
                                <div v-if="item.status == 1" class="no-red">禁</div>
                            </div>
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div v-if="$route.query.type == 'user'">
                <div class="search_item">
                    <span class="search_item_title">服务商名称</span>
                    <el-select size="mini" v-model="searchForm.amountId" placeholder="请选择查询的服务商" @change="setDataList"
                        clearable filterable>
                        <el-option v-for="(item, index) in select_list" :key="index" :label="item.name"
                            :value="item.id"></el-option>
                    </el-select>
                </div>
            </div>

            <div class="search_item">
                <span class="search_item_title">日期</span>
                <el-date-picker value-format="YYYY-MM-DD" :clearable="false" style="width:380px" size="mini" v-model="timeList" @change="setDataList"
                    type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
            </div>
            <div class="search_item">
                <span class="search_item_title">对比日期</span>
                <el-date-picker value-format="YYYY-MM-DD" style="width:380px" size="mini" v-model="contrastList" @change="setDataList"
                    type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
            </div>
        </div>
        <!-- 3.汇总 -->
        <div>
            <el-table class="eltable" v-loading="loading" ref="multipleTable" size="mini" :data="countList"
                tooltip-effect="dark" style="width: 100%" :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
                row-key="id" :max-height="tableHeight" default-expand-all :row-style="{ height: '60px' }"
                :cell-style="{ padding: '0px' }" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
                <el-table-column label="汇总">
                    <template #default="">{{ total }}</template>
                </el-table-column>
                <el-table-column label="呼叫次数">
                    <template #default="scope">{{ scope.row.billNum != null ? scope.row.billNum : '--' }}</template>
                </el-table-column>
                <el-table-column label="对比上期">
                    <template #default="scope">
                        <span :style="{ color: colorPipes(scope.row.billNumContrast) }">
                            {{ contrastPipes(scope.row.billNumContrast) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="接听次数">
                    <template #default="scope">{{ scope.row.callNum != null ? scope.row.callNum : '--' }}</template>
                </el-table-column>
                <el-table-column label="对比上期">
                    <template #default="scope">
                        <span :style="{ color: colorPipes(scope.row.callNumContrast) }">
                            {{ contrastPipes(scope.row.callNumContrast) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="分钟数">
                    <template #default="scope">{{ scope.row.callTime != null ? scope.row.callTime : '--' }}</template>
                </el-table-column>
                <el-table-column label="对比上期">
                    <template #default="scope">
                        <span :style="{ color: colorPipes(scope.row.callTimeContrast) }">
                            {{ contrastPipes(scope.row.callTimeContrast) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="通话费(元)">
                    <template #default="scope">{{ scope.row.callCharge != null ? scope.row.callCharge : '--' }}</template>
                </el-table-column>
                <el-table-column label="对比上期">
                    <template #default="scope">
                        <span :style="{ color: colorPipes(scope.row.originalPriceContrast) }">
                            {{ contrastPipes(scope.row.originalPriceContrast) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="成本话费(元)">
                    <template #default="scope">{{ scope.row.costPrice != null ? scope.row.costPrice : '--' }}</template>
                </el-table-column>
                <el-table-column label="对比上期">
                    <template #default="scope">
                        <span :style="{ color: colorPipes(scope.row.costPriceContrast) }">
                            {{ contrastPipes(scope.row.costPriceContrast) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="利润(元)">
                    <template #default="scope">{{ scope.row.callProfit != null ? scope.row.callProfit : '--' }}</template>
                </el-table-column>
                <el-table-column label="对比上期">
                    <template #default="scope">
                        <span :style="{ color: colorPipes(scope.row.callProfitContrast) }">
                            {{ contrastPipes(scope.row.callProfitContrast) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="取整分钟">
                    <template #default="scope">{{ scope.row.roundMinutes != null ? scope.row.roundMinutes : '--'
                    }}</template>
                </el-table-column>
                <el-table-column label="对比上期">
                    <template #default="scope">
                        <span :style="{ color: colorPipes(scope.row.roundMinutesContrast) }">
                            {{ contrastPipes(scope.row.roundMinutesContrast) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="取整利润">
                    <template #default="scope">
                        {{ scope.row.roundPrice != null ? scope.row.roundPrice : '--' }}</template>
                </el-table-column>
                <el-table-column label="对比上期">
                    <template #default="scope">
                        <span :style="{ color: colorPipes(scope.row.roundPriceContrast) }">
                            {{ contrastPipes(scope.row.roundPriceContrast) }} </span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 4.数据表格 -->
        <div>
            <el-table class="eltable" v-loading="loading" ref="multipleTable" size="mini" :data="downTable"
                tooltip-effect="dark" style="width: 100%" :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
                row-key="id" :max-height="tableHeight" default-expand-all @sort-change="changeTableSort"
                :row-style="{ height: '50px' }" :cell-style="{ padding: '0px' }"
                :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
                <el-table-column v-if="$route.query.type == 'amount'" fixed label="企业名称">
                    <template #default="scope">{{ scope.row.company != null ? scope.row.company : '-' }}</template>
                </el-table-column>
                <el-table-column v-if="$route.query.type == 'user'" fixed label="服务商">
                    <template #default="scope">{{ scope.row.amountName != null ? scope.row.amountName : '-' }}</template>
                </el-table-column>
                <el-table-column label="呼叫次数">
                    <template #default="scope">{{ scope.row.billNum != null ? scope.row.billNum : '--' }}</template>
                </el-table-column>
                <el-table-column label="对比上期">
                    <template #default="scope">
                        <span :style="{ color: colorPipes(scope.row.billNumContrast) }">
                            {{ contrastPipes(scope.row.billNumContrast) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="接听次数">
                    <template #default="scope">{{ scope.row.callNum != null ? scope.row.callNum : '--' }}</template>
                </el-table-column>
                <el-table-column label="对比上期">
                    <template #default="scope">
                        <span :style="{ color: colorPipes(scope.row.callNumContrast) }">
                            {{ contrastPipes(scope.row.callNumContrast) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="分钟数">
                    <template #default="scope">{{ scope.row.callTime != null ? scope.row.callTime : '--' }}</template>
                </el-table-column>
                <el-table-column label="对比上期">
                    <template #default="scope">
                        <span :style="{ color: colorPipes(scope.row.callTimeContrast) }">
                            {{ contrastPipes(scope.row.callTimeContrast) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="通话费(元)">
                    <template #default="scope">{{ scope.row.callCharge != null ? scope.row.callCharge : '--' }}</template>
                </el-table-column>
                <el-table-column label="对比上期">
                    <template #default="scope">
                        <span :style="{ color: colorPipes(scope.row.originalPriceContrast) }">
                            {{ contrastPipes(scope.row.originalPriceContrast) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="成本话费(元)">
                    <template #default="scope">{{ scope.row.costPrice != null ? scope.row.costPrice : '--' }}</template>
                </el-table-column>
                <el-table-column label="对比上期">
                    <template #default="scope">
                        <span :style="{ color: colorPipes(scope.row.costPriceContrast) }">
                            {{ contrastPipes(scope.row.costPriceContrast) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="利润(元)">
                    <template #default="scope">{{ scope.row.callProfit != null ? scope.row.callProfit : '--' }}</template>
                </el-table-column>
                <el-table-column label="对比上期">
                    <template #default="scope">
                        <span :style="{ color: colorPipes(scope.row.callProfitContrast) }">
                            {{ contrastPipes(scope.row.callProfitContrast) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="取整分钟">
                    <template #default="scope">{{ scope.row.roundMinutes != null ? scope.row.roundMinutes : '--'
                    }}</template>
                </el-table-column>
                <el-table-column label="对比上期">
                    <template #default="scope">
                        <span :style="{ color: colorPipes(scope.row.roundMinutesContrast) }">
                            {{ contrastPipes(scope.row.roundMinutesContrast) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="取整利润">
                    <template #default="scope">
                        {{ scope.row.roundPrice != null ? scope.row.roundPrice : '--' }}</template>
                </el-table-column>
                <el-table-column label="对比上期">
                    <template #default="scope">
                        <span :style="{ color: colorPipes(scope.row.roundPriceContrast) }">
                            {{ contrastPipes(scope.row.roundPriceContrast) }} </span>
                    </template>
                </el-table-column>

            </el-table>
        </div>
        <!-- 5.分页器 -->
        <div class="flex-nowrap-flex-end pagination-box">
            <el-pagination v-model="searchForm.page" background :page-sizes="[10, 50, 100, 200]"
                :page-size="searchForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"
                @size-change="handleSizeChange" @current-change="handleCurrentChange">
            </el-pagination>
        </div>
    </div>
</template>
  
<script>
import userNumberCount from '@/api/open/userNumberCount/userNumberCount'
import { getDate, getMonday, getMonth } from '@/utils/timeToChoose.js'
import amountts from '@/api/open/privacy/amount'
import interfacets from '@/api/open/privacy/interface'
import userts from '@/api/web/user'
import { IconFont } from '@/utils/iconfont'
import { ElMessage } from 'element-plus'
import { message } from 'ant-design-vue'
export default {
    name: 'Newbill',
    components: {
        IconFont,
    },
    data() {
        const validateMinutes = (rule, value, callback) => {
            let reg = /^[0-9]\d*$/
            if (!value) {
                callback()
            } else if (!reg.test(value)) {
                callback(new Error('分钟数输入格式有误，请不要输入除数字外的其他字符'))
            } else {
                callback()
            }
        }
        return {
            queryId:'',
            exportLoading: false,
            searchForm: {
                page: 1,
                pageSize: 10,
                amountId: '',
                groupBy: [],
                sortBy: [],
                sortDesc: [],
                uid: '',
                dateBegin: '',
                userId: '',
                dateEnd: '',
                contrastDateBegin: '',
                contrastDateEnd: ''
            },
            select_list: [],
            total: 0,
            timeList: [],
            contrastList: [],
            loading: false,
            countList: [],
            downTable: [],

            tableHeight: 540,
        }
    },
    mounted() {
        this.refreshData()
        this.getDownLowData()
    },
    methods: {
        colorPipes(value) {
            if (!value && value !== 0) return
            let data = Number(value.split('%')[0])
            return data > 0 ? 'red' : data < 0 ? 'green' : 'black'
        },
        contrastPipes(value) {
            if (!value && value !== 0) return '0.00%'

            let data = Number(value.split('%')[0])
            return data > 0 ? `+${data.toFixed(2)}%` : `${data.toFixed(2)}%`
        },
        backOut() {
            this.$router.back(-1)
        },
        exportData() {
            this.exportLoading = true
            userNumberCount.accountDetailExport(
                {
                    ...this.searchForm
                }
            ).then(res => {
                let { code } = res
                if (code == 200) {
                    this.$message.info('正在导出中,请稍后...')
                }
            }).finally(() => {
                this.exportLoading = false
            })
        },
        getDownLowData() {
            switch (this.$route.query.type) {
                case 'user':
                    amountts.getAmountList({}).then((res) => {
                        this.select_list = res.data
                    })
                    break;

                case 'amount':
                    userts.queryList({}).then((res) => {
                        this.select_list = res.data
                    })
                    break;
            }

        },
        setDataList() {
            this.timeList == null && (this.timeList = [])
            this.contrastList == null && (this.contrastList = [])
            this.searchForm.dateBegin = this.timeList[0]
            this.searchForm.dateEnd = this.timeList[1]
            this.searchForm.contrastDateBegin = this.contrastList[0]
            this.searchForm.contrastDateEnd = this.contrastList[1]
            this.getTableList()
        },
        //主叫单表数据
        getTableList() {
            this.loading = true
            userNumberCount
                .accountDetailLine({
                    ...this.searchForm,
                })
                .then((res) => {
                    if (res.code !== 200) {
                        this.$message.error(res.message)
                        return false
                    } else {
                        this.countList = res.data.count ? [res.data.count] : []
                        this.downTable = res.data.page.records
                        this.total = res.data.page.total
                    }
                }).finally(() => {
                    this.loading = false
                })
        },
        // 刷新
        refreshData() {
            this.searchForm = {
                page: 1,
                pageSize: 10,
                amountId:   this.$route.query.type=='amount'?sessionStorage.getItem('amountId'):'',
                groupBy: [this.$route.query.type == 'amount' ? 'user' : 'amount'],
                sortBy: ['date'],
                sortDesc: [true],
                uid: this.$route.query.type=='amount'?'':sessionStorage.getItem('amountId'),             
                dateBegin: this.$route.query.dateBegin,
                dateEnd: this.$route.query.dateEnd,
                contrastDateBegin: '',
                contrastDateEnd: ''
            }
            this.timeList = [this.searchForm.dateBegin, this.searchForm.dateEnd]
            this.contrastList = []
            this.getTableList()
        },
        // 分页
        handleSizeChange(val) {
            this.searchForm.pageSize = val
            this.getTableList()
        },
        handleCurrentChange(val) {
            this.searchForm.page = val
            this.getTableList()
        },
        // 表格排序
        changeTableSort(column) {
            if (column.prop == null) {
                ElMessage({
                    showClose: true,
                    message: '当前已是向下排序',
                    type: 'info',
                })
            } else {
                this.searchForm.sortBy = [column.prop]
                column.order == 'ascending'
                    ? (this.searchForm.sortDesc = [false])
                    : (this.searchForm.sortDesc = [true])
                this.getDownList(true)
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.search_item {
    margin-right: 20px;

    &:last-of-type {
        margin-right: 0px;
    }

    .search_item_title {
        margin-right: 8px;
    }
}

.searchForm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .pageHeader {
        margin-right: 20px;

        ::v-deep .el-page-header__content {
            font-size: 16px;
        }
    }
}

.newbill {
    padding-right: 1.125rem;
}

.first-name {
    font-size: 14px;
    font-weight: 400;
    color: #131523;
}

.pagination-box {
    height: 60px;
}

.title-span {
    display: inline-block;
    width: 80px;
}
</style>
  